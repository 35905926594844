import React, { useEffect, useState } from "react";
import { getDefaultLanguage, getTranslation } from "../translation";
import Button from "@mui/material/Button";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { genericComponentProps } from "../interfaces";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import ImageSrc from "../img/office.png"; // Adjust the path accordingly
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Language } from "@mui/icons-material";
import Overlay from "material-ui/internal/Overlay";

interface Employee {
  name: string;
  surname: string;
  position: string;
  tel: string;
  email: string;
  img: string;
  big_img: string;
}

type Language = "mk" | "en" | "de";


export default function OurTeam({ isInHomepageView }: genericComponentProps) {
  const [showDetails, setShowDetails] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<any | null>(null); // Track the selected employee
  const navigate = useNavigate(); // Get the navigate function from react-router-dom
  const { employeeId } = useParams();
  const location = useLocation()
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(
    getDefaultLanguage()
  );
  const [employees, setEmployees] = useState<any[]>([
    {
      email: "zlatkokolevski@gmail.com",
      name: "Zlatko",
      position: "Attorney",
      mk_position: "Адвокат",
      de_position: "Attorney",
      img: "https://storage.googleapis.com/nkit_eshop/Remove%20background%20project.webp",
      surname: "Kolevski",
      tel: "+389 7077 777",
      big_img: "https://storage.googleapis.com/nkit_eshop/zlatko_big2.webp",
      areas: [
        "Commercial Law",
        "Corporate Law",
        "Banking Law",
        "Media Law",
        "Administrative Law",
        "Real Estate Law",
        "Aviation Law",
        "Labor & Employment Law",
        "Litigation",
        "Property Law",
        "Bankruptcy Law",
      ],
     
      memberSince: "1992",
      education: [
        "Ss. Cyril and Methodius University - Faculty of Law (Skopje, 1983-1989)",
        "Specialized in Administrative, Corporate and Civil Law",
      ],
      languages: ["English", "Croatian", "Serbian"],
      experience: `Zlatko T. Kolevski is a highly respected attorney with over 32 years of experience in a broad spectrum of legal matters. He has served on government teams drafting new laws for companies and non-litigation procedures and has provided legal expertise for projects undertaken by the World Bank, UN-FAO, and OSCE.`,
    },
    {
      email: "sofija@gmail.com",
      name: "Sofija",
      position: "Lawyer",
      surname: "Naceva",
      img: "https://storage.googleapis.com/nkit_eshop/sofija_240.webp",
      big_img: "https://storage.googleapis.com/nkit_eshop/big_sofija.webp",
      tel: "+389 7077 888",
      areas: [
        "Commercial Law",
        "Family Law",
        "Inheritance Law",
        "Real Estate",
        "Labor Law",
        "Litigation"
      ],
      memberSince: "2000",
      lawyerSince: "2005",
      education: [
        "Ss. Cyril and Methodius University – Faculty of Law 'Justinian the First' – Skopje (1995)",
        "Passed the Bar Exam (1998)"
      ],
      languages: ["Serbian", "Croatian", "Bulgarian"]
    },
    {
      email: "vska@gmail.com",
      name: "Misho",
      position: "Lawyer",
      surname: "Smilevski",
      img: "https://storage.googleapis.com/nkit_eshop/misho_240.webp",
      big_img: "https://storage.googleapis.com/nkit_eshop/big_misho.webp",
      tel: "+389 7077 888",
      areas: [
        "Criminal Law",
        "Medical Law",
        "Compensation Claims",
        "Commercial Law",
        "Litigation"
      ],
      memberSince: "2009",
      lawyerSince: "2008",
      education: [
        "Ss. Cyril and Methodius University – Faculty of Law 'Justinian the First' – Skopje (2007)",
        "Passed the Bar Exam (2009)"
      ],
      languages: ["English", "Serbian", "Croatian"]
    },
    {
      email: "vska@gmail.com",
      name: "Neda",
      position: "Lawyer",
      img: "https://storage.googleapis.com/nkit_eshop/neda_240.webp",
      surname: "Karapanovska",
      big_img: "https://storage.googleapis.com/nkit_eshop/bigger_image_neda.webp",
      tel: "+389 7077 888",
      areas: [
        "Administrative Law",
        "Tax Law",
        "Inheritance Law",
        "Commercial Law",
        "Corporate Law"
      ],
      memberSince: "2016",
      associateSince: "2010",
      education: [
        "Ss. Cyril and Methodius University – Faculty of Law 'Justinian the First' – Skopje (2010)",
        "Passed the Bar Exam (2015)"
      ],
      languages: ["English", "Serbian", "Croatian", "Spanish"]
    },
    {
      email: "tijanamarkovic@gmail.com",
      name: "Tijana",
      position: "Lawyer",
      img: "https://storage.googleapis.com/nkit_eshop/file.webp",
      big_img: "https://storage.googleapis.com/nkit_eshop/big_tijana.webp",
      surname: "Markovikj",
      tel: "+389 7077 888",
      areas: [
        "International Law",
        "Immigration Law",
        "Education",
        "Commercial Law",
        "Corporate Law"
      ],
      memberSince: "2013",
      associateSince: "2012",
      education: [
        "Ss. Cyril and Methodius University – Faculty of Law 'Justinian the First' – Skopje",
        "Master's degree in Public International Law, Private International Law, International Relations, and EU Law (2011)",
        "Passed the Bar Exam (2012)"
      ],
      languages: ["English", "Serbian", "Croatian"]
    },    
    {
      email: "tomekolevski@gmail.com",
      img: "https://storage.googleapis.com/nkit_eshop/tome_240.webp",
      name: "Tome",
      position: "Lawyer",
      surname: "Kolevski",
      tel: "+389 7077 888",
      big_img: "https://storage.googleapis.com/nkit_eshop/big_tome.webp",
      areas: [
        "Financial Law",
        "Enforcement Law",
        "Real Estate",
        "Inheritance Law",
        "Administrative Law"
      ],
      memberSince: "2021",
      associateSince: "2017",
      education: [
        "Ss. Cyril and Methodius University – Faculty of Law 'Justinian the First' – Skopje",
        "Master's degree in Financial Law and Finance (2018)",
        "Passed the Bar Exam (2019)"
      ],
      languages: ["English", "Serbian", "Croatian", "Greek"]
    },    
   
   
    {
      email: "mihaelakolevska@gmail.com",
      name: "Mihaela",
      position: "Paralegal",
      surname: "Kolevska",
      img: "https://storage.googleapis.com/nkit_eshop/mihaela_240.webp",
      tel: "+389 7077 111",
      big_img: "https://storage.googleapis.com/nkit_eshop/mihaela_big.webp",
      areas: [
        "Legal Research",
        "Client Communication",
        "Administrative Tasks and Organization",
        "Legal Document Preparation",
      ],
      memberSince: "2022",
      education: [
        "Ss. Cyril and Methodius University - Faculty of Law 'Justinian the First' - Skopje",
        "LLM Candidate in Business Law (2024-2025)",
      ],
      languages: ["English", "German", "Serbian", "Croatian"],
      experience: `Mihaela Kolevska is a dedicated and detail-oriented paralegal with a demonstrated passion for the legal field. She brings a strong academic background and a profound interest in business law to her role at Law Office Kolevski. Mihaela is skilled in conducting legal research, effectively communicating with clients, and managing administrative tasks with organization and efficiency. She is also proficient in preparing legal documents, ensuring accuracy and adherence to legal standards.`,
    }
  ]);

  const styles = {
    container: { padding: "0rem 10rem", background: "white", marginBottom:'1rem', overlay:'none' },
    header: { fontSize: "3.5rem" },
    subHeader: { fontSize: "1.5rem", lineHeight: "3.5rem" },
    hr: { background: "#013A4E", height: "2px", margin: "0.3rem 0rem", width: "5.5rem" },
    image: { width: "auto", height: "450px" },
    sectionTitle: { fontSize: "24px", lineHeight: "27px", fontWeight: "400", color: "#013A4E" },
    sectionContent: { fontSize: "18px", lineHeight: "27px", fontWeight: "400", color: "#013A4E" },
  };

  useEffect(() => {
    console.log(employeeId);
    let parts = employeeId?.split("-") as string[];
    console.log(parts);
  
    if (employeeId) {
      const foundEmployee = employees.find(
        (emp) =>
          emp.name.toLowerCase() === parts[0] &&
          emp.surname.toLowerCase() === parts[1]
      );
  
      if (foundEmployee) {
        setShowDetails(true);
        window.scrollTo({
          top: 0,
          behavior: "smooth", // You can use "auto" instead of "smooth" for instant scroll
        });
        setSelectedEmployee(foundEmployee);
      } else {
        // Employee not found, handle accordingly (e.g., show an error message)
        setShowDetails(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth", // You can use "auto" instead of "smooth" for instant scroll
        });
      }
    } else {
      setShowDetails(false);
      window.scrollTo({
        top: 0,
        behavior: "smooth", // You can use "auto" instead of "smooth" for instant scroll
      });
    }
  }, [employeeId, employees]);  // Include employeeId in the dependency array
  
  const handleShowDetails = (employee: Employee) => {
    setSelectedEmployee(employee);
    setShowDetails(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // You can use "auto" instead of "smooth" for instant scroll
    });

    const decodedPath = decodeURIComponent(location.pathname);
debugger
    // Use navigate to update the URL
    if(decodedPath === '/our-team') {
      navigate(`/our-team/${employee.name.toLowerCase()}-${employee.surname.toLowerCase()}`);
    } else {
      navigate(`/нашиот-тим/${employee.name.toLowerCase()}-${employee.surname.toLowerCase()}`);
    }
    
  };

  const handleGoBack = () => {
    setShowDetails(false);
    setSelectedEmployee(null);
    // Use navigate to go back to the team view
    navigate("/our-team");
  };

  interface TeamMemberProps {
    employee: any;
    row: number;
    isMobile:boolean;
  }

  const TeamMember: React.FC<TeamMemberProps> = ({ employee, row ,isMobile }) => {
 

    return (
      <div
        style={{
          minHeight:  isMobile ? "auto":"270px",
          maxHeight: isMobile ? "auto":"270px",
          borderRadius: "30px",
          background: "#F6F4F2",
          minWidth: isMobile ? row === 1 ? "100%" : "50%": row === 1 ? "40%" : "23rem",
          maxWidth:  isMobile ? row === 1 ? "100%" : "50%": row === 1 ? "40%" : "",
          display: "flex",
          flexDirection: isMobile && row !=1 ? "row":"column",
          paddingRight: isMobile && row ==1 ? "0rem":"0rem",
          justifyContent: "space-between",
          transition: "background-color 0.3s ease", // Add a smooth transition
        }}
        onMouseEnter={(e) => {
          // e.currentTarget.style.display = 'none';
          const memberPhotoDiv = e.currentTarget.querySelector(
            "#member_front"
          ) as any;
          if (memberPhotoDiv) {
            memberPhotoDiv.style.display = "none";
          }
          const memberPhotoDivBack = e.currentTarget.querySelector(
            "#member_back"
          ) as any;
          if (memberPhotoDivBack) {
            memberPhotoDivBack.style.display = "flex";
          }
          const button = e.currentTarget.querySelector("#button_read") as any;
          if (button) {
            button.style.display = "flex";
          }
        }}
        onMouseLeave={(e) => {
          const memberPhotoDiv = e.currentTarget.querySelector(
            "#member_front"
          ) as any;
          if (memberPhotoDiv) {
            memberPhotoDiv.style.display = "flex";
          }
          const memberPhotoDivBack = e.currentTarget.querySelector(
            "#member_back"
          ) as any;
          if (memberPhotoDivBack) {
            memberPhotoDivBack.style.display = "none";
          }
          const button = e.currentTarget.querySelector("#button_read") as any;
          if (button) {
            button.style.display = "none";
          }
        }}
      >
        <div
          className="flexDa"
          id="member_front"
          style={{
            display: "flex",
            flexDirection: isMobile ? "column":"row",
            width: isMobile ? "auto":"auto",
            justifyContent: "space-between",
            height: isMobile ? "auto":"auto",
            padding: isMobile ? "1.5rem 1rem 0rem 2rem" : "2rem 2rem 0rem 2rem",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{
                fontSize: "28px",
                lineHeight: "42px",
                fontWeight: "500",
              }}
            >
              {employee.name}
            </span>
            <span
              style={{
                fontSize: "clamp(16px, 2.5vw, 28px)", // Dynamic font size
                lineHeight: "clamp(24px, 3.5vw, 42px)", // Dynamic line height
                fontWeight: "500",
              }}
            >
              {employee.surname}
            </span>
            <span
              style={{
                fontSize: "14px",
                lineHeight: "25px",
                fontWeight: "500",
              }}
            >
            {getTranslation(`${employee.name}.position`)}

            </span>
          </div>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <img height="240px" width="auto" src={employee.img} alt="" />
          </div>
        </div>
        <div
          id="member_back"
          style={{
            display: "none",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
            height: "100%",
            padding: "2rem",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{
                fontSize: "28px",
                lineHeight: "42px",
                fontWeight: "500",
              }}
            >
              {employee.name}
            </span>
            <span
              style={{
                fontSize: "28px",
                lineHeight: "42px",
                fontWeight: "500",
              }}
            >
              {employee.surname}
            </span>
            <span
              style={{
                fontSize: "14px",
                lineHeight: "25px",
                fontWeight: "500",
              }}
            >
            {getTranslation(`${employee.name}.position`)}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              className="dropdown-header"
              style={{ justifyContent: "start" }}
            >
              <LocalPhoneIcon
                style={{ paddingRight: "0.5rem", color: "#013A4E" }}
              />
              <span className="tablet_mobile">{employee.tel}</span>
            </div>
            <div
              className="dropdown-header"
              style={{ justifyContent: "start" }}
            >
              <EmailIcon style={{ paddingRight: "0.5rem", color: "#013A4E" }} />
              <span className="tablet_mobile">{employee.email}</span>
            </div>
          </div>
        </div>
        <Button
          id="button_read"
          style={{
            display: "none",
            background: "#013A4E",
            color: "white",
            borderBottomLeftRadius: "30px",
            borderBottomRightRadius: "30px",
          }}
          // onClick={() => {
            
          //   setShowDetails(true);
          // }}
          onClick={() => handleShowDetails(employee)}

        >
          {getTranslation("read_more")}
        </Button>
      </div>
    );
  };
  return (
    <>
      {showDetails ? (
        (selectedEmployee &&  
          <div id="details">
          <div id="our_team" className="our_team-container our_team-flex-row" style={{ justifyContent: "space-between", alignItems: "center" }}>
            <div className="our_team-flex-column" style={{ justifyContent: "start" }}>
              <span className="our_team-header">
                {getTranslation(`${selectedEmployee.name}.name`)} {getTranslation(`${selectedEmployee.name}.surname`)}
              </span>
              <span className="our_team-sub-header">{getTranslation(`${selectedEmployee.name}.position`)}</span>
              <hr className="our_team-hr" />
            </div>
            <div id="employee_img" className="our_team-employee-img">
              <img
                className="our_team-image"
                src={getTranslation(`${selectedEmployee.name}.big_img`)}
                alt={`${getTranslation(`${selectedEmployee.name}.name`)} ${getTranslation(`${selectedEmployee.name}.surname`)}`}
              />
            </div>
          </div>
          <div className="our_team-container data-employee our_team-flex-row" style={{ minHeight: "60vh", marginTop: "5rem", marginBottom: "10rem" }}>
            <div className="our_team-flex-column" style={{ width: "100%", paddingLeft: "1.5rem" }}>
              <span className="our_team-section-title" style={{ fontSize: "32px", lineHeight: "68px", fontWeight: "500" }}>
                {getTranslation("employee_about")} {getTranslation(`${selectedEmployee.name}.name`)} {getTranslation(`${selectedEmployee.name}.surname`)}
              </span>
              <span className="our_team-section-title">{getTranslation("Areas_of_Practice")}</span>
              <ul className="our_team-section-content">
                {getTranslation(`${selectedEmployee.name}.areas`).split(",").map((area: string, index: number) => (
                  <li key={index}>{area.trim()}</li>
                ))}
              </ul>
              <span className="our_team-section-title">{getTranslation("Education")}</span>
              <ul className="our_team-section-content">
                {getTranslation(`${selectedEmployee.name}.education`).split(";").map((edu: string, index: number) => (
                  <li key={index}>{edu.trim()}</li>
                ))}
              </ul>
              <span className="our_team-section-title">{getTranslation("Languages")}</span>
              <ul className="our_team-section-content">
                {getTranslation(`${selectedEmployee.name}.languages`).split(",").map((lang: string, index: number) => (
                  <li key={index}>{lang.trim()}</li>
                ))}
              </ul>
              <span className="our_team-section-title">{getTranslation("Experience")}</span>
              <p className="our_team-section-content">{getTranslation(`${selectedEmployee.name}.experience`)}</p>
            </div>
            <div style={{ width: "25%" }}>
              <div className="our_team-contact-card">
                <span style={{ fontSize: "32px", lineHeight: "48px" }}>{getTranslation("contact")}</span>
                <hr className="our_team-hr" style={{ width: "3rem" }} />
                <div className="our_team-flex-column" style={{ marginTop: "2rem" }}>
                  <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
                    <LocalPhoneIcon style={{ paddingRight: "0.5rem", color: "#013A4E" }} />
                    <span>{getTranslation(`${selectedEmployee.name}.tel`)}</span>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <EmailIcon style={{ paddingRight: "0.5rem", color: "#013A4E" }} />
                    <span>{getTranslation(`${selectedEmployee.name}.email`)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        )
      ) : (
        <>
          <div id="our_team">
            <span style={{ fontSize: "3.5rem" }}>
              {getTranslation("our_team.header")}
              <hr
                style={{
                  background: "#013A4E",
                  height: "2px",
                  margin: "0.3rem 0rem 0rem 0rem",
                  width: "5.5rem",
                }}
              />
            </span>
          {/* desktopppppppppppppp */}
            <div
            className="small_screen_hide"
              style={{
                marginTop: "5rem",
                height: "auto",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {employees.slice(0, 1).map((Employee, index) => {
                return (
                  <>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        gap: "50px",
                        padding: "0rem 0rem 5rem 0rem",
                      }}
                      key={index}
                    >
                      <TeamMember key={index} isMobile={false} employee={employees[0]} row={1} />
                    </div>
                  </>
                );
              })}

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "50px",
                  padding: "0rem 0rem 5rem 0rem",
                }}
              >
                {employees[1] ? (
                  <>
                    <TeamMember isMobile={false}  employee={employees[1]} row={2} />
                  </>
                ) : (
                  <></>
                )}

                {employees[2] ? (
                  <>
                    <TeamMember isMobile={false}  employee={employees[2]} row={2} />
                  </>
                ) : (
                  <></>
                )}

                {employees[3] ? (
                  <>
                    <TeamMember isMobile={false}  employee={employees[3]} row={2} />
                  </>
                ) : (
                  <></>
                )}

                {/* <div>SHOW INFO</div> */}
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "50px",
                  padding: "0rem 0rem 5rem 0rem",
                }}
              >
                {employees[4] ? (
                  <>
                    <TeamMember isMobile={false}  employee={employees[4]} row={2} />
                  </>
                ) : (
                  <></>
                )}

                {employees[5] ? (
                  <>
                    <TeamMember isMobile={false}  employee={employees[5]} row={2} />
                  </>
                ) : (
                  <></>
                )}

                {employees[6] ? (
                  <>
                    <TeamMember isMobile={false}  employee={employees[6]} row={2} />
                  </>
                ) : (
                  <></>
                )}
                {employees[7] ? (
                  <>
                    <TeamMember isMobile={false}  employee={employees[7]} row={2} />
                  </>
                ) : (
                  <></>
                )}

               
              </div>
            </div>
          {/* tablet */}
                     <div
            className="mobile_screen_show"
              style={{
                marginTop: "5rem",
                height: "auto",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {employees.slice(0, 1).map((Employee, index) => {
                return (
                  <>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        gap: "50px",
                        padding: "0rem 0rem 5rem 0rem",
                      }}
                      key={index}
                    >
                      <TeamMember key={index} isMobile={false} employee={employees[0]} row={1} />
                    </div>
                  </>
                );
              })}
         
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "10px",
                  padding: "0rem 0rem 5rem 0rem",
                }}
              >
                {employees[1] ? (
                  <>
                    <TeamMember isMobile={false}  employee={employees[1]} row={2} />
                  </>
                ) : (
                  <></>
                )}

                {employees[2] ? (
                  <>
                    <TeamMember isMobile={false}  employee={employees[2]} row={2} />
                  </>
                ) : (
                  <></>
                )}

                {/* <div>SHOW INFO</div> */}
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "50px",
                  padding: "0rem 0rem 5rem 0rem",
                }}
              >{employees[3] ? (
                <>
                  <TeamMember isMobile={false}  employee={employees[3]} row={2} />
                </>
              ) : (
                <></>
              )}
                {employees[4] ? (
                  <>
                    <TeamMember isMobile={false}  employee={employees[4]} row={2} />
                  </>
                ) : (
                  <></>
                )}
               
              </div>
            </div>
             {/* mobile */}
             <div
            className="mobile_screen_show_smallest"
              style={{
                marginTop: "5rem",
                height: "auto",
                display: "flex",
                flexDirection: "column",
                padding:'0rem 0rem'
              }}
            >
              {employees.map((Employee, index) => {
                return (
                  <>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        gap: "50px",
                        padding: "0rem 0rem 5rem 0rem",
                      }}
                      key={index}
                    >
                      <TeamMember key={index} isMobile={true} employee={Employee} row={1} />
                    </div>
                  </>
                );
              })}
         
            </div>
          </div>
        </>
      )}
    </>
  );
}
